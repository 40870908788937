'use strict';

let View;

const account = require('models/account'),
  FilterOnEvent = require('FilterOnEvent'),
  TreeItemFactory = require('../utils/TreeItemFactory'),
  Confirm = require('views/confirmView'),
  TreeVisualiserView = require('views/treeVisualiserView'),
  LocalesView = require('views/localesModalView'),
  ProgramDisabledNodeModalView = require('views/programDisabledNodeModalView'),
  ProgramMultipleDisabledNodeModalView = require('views/programMultipleDisabledNodeModalView'),
  EditNodeModalView = require('views/editNodeModalView'),
  EditTagsModalView = require('views/editTagModalView'),
  EditMultipleTagsModalView = require('views/editMultipleTagModalView'),
  SelectNodeModalView = require('views/selectNodeModalView'),
  ReleaseTree = require('models/releaseTree'),
  MarkdownCheatsheetModalView = require('views/markdownCheatsheetModalView'),
  isProgramDisabled = require('utils/isProgramDisabled'),
  TagsUtils = require('utils/TagsUtils'),
  TreeItemTools = require('utils/TreeItemTools'),
  UrlUtils = require('utils/UrlUtils'),
  { getRelevantSharedDataReferenceNames } = require('utils/clubmedPagesContents'),
  { getReleventSharedCommercialComponentsReferenceNames } = require('utils/commercialAnimationUtils'),
  Utils = require('utils/utils');
const { PAGES_STR, getSharedDataProperty } = require('../utils/clubmedPagesContents');

function close() {
  this.$el.remove();
  this.off();
  if (this.model) {
    this.model.off(null, null, this);
  }
  if (this.tree) {
    this.tree.remove();
  }
  if (this.treeVisualiserView) {
    this.treeVisualiserView.close();
  }
  FilterOnEvent.off('filterOn');
}

function createModel() {
  var value = this.release.get('value');
  this.model = new ReleaseTree();
  this.model.set(this.model.createTreeNode('', '', value).nodes);
  this.model.setContent(this.content);
  this.model.setRelease(this.release);
  this.model.setTreeView(this);
  this.model.on('userButtonClicked', this.onButtonClicked, this);
  this.pageToCopy = null;
}

function initSpecialInputs() {
  $('.selectpicker').selectpicker();

  $('.selectpicker.dcxSharedComponentsReferenceNamePicker').on('show.bs.select', (e) => {
    this.updateReferenceNameDropdown(e.target);
  });

  $('.selectpicker.dcxSharedCommercialComponentsReferenceNamePicker').on('show.bs.select', (e) => {
    this.updateCommercialReferenceNameDropdown(e.target);
  });

  $('.markdown-basic').each(function () {
    var $currentTextarea = $(this);
    if (!$currentTextarea.hasClass('md-input')) {
      $currentTextarea.markdown({
        autofocus: false,
        savable: false,
        hideable: false,
        width: 'markdown editable value no-dnd',
        resize: 'none',
        iconlibrary: 'fa',
        language: 'en',
        fullscreen: {
          enable: false,
          icons: {},
        },
        hiddenButtons: [
          'cmdUrl',
          'cmdHeading',
          'cmdImage',
          'cmdList',
          'cmdListO',
          'cmdCode',
          'cmdQuote',
        ],
        parser: marked.setOptions({
          gfm: true,
          tables: false,
          breaks: true,
          pedantic: false,
          sanitize: true,
          smartLists: true,
          smartypants: false,
        }),
        additionalButtons: [
          [{
            name: 'groupCustomLink',
            data: [{
              name: 'cmdCustomUrl',
              title: 'URL/Link',
              hotkey: 'Ctrl+L',
              icon: { glyph: 'glyphicon glyphicon-link', fa: 'fa fa-link', 'fa-3': 'icon-link' },
              callback: function (e) {
                // Give [] surround the selection and prepend the link
                var chunk,
                  cursor,
                  selected = e.getSelection(),
                  link;

                if (selected.length === 0) {
                  // Give extra word
                  chunk = e.__localize('');
                } else {
                  chunk = selected.text;
                }

                link = prompt(e.__localize('Insert URL'), 'https://');

                var urlRegex = new RegExp('^((http|https)://|(mailto:))[a-z0-9]', 'i');
                if (link !== null) {
                  if (link !== '' && link !== 'http://' && link !== 'https://' && urlRegex.test(link)) {
                    var sanitizedLink = $('<div>' + link + '</div>').text();

                    // transform selection and set the cursor into chunked text
                    e.replaceSelection('[' + chunk + '](' + sanitizedLink + ')');
                    cursor = selected.start + 1;

                    // Set the cursor
                    e.setSelection(cursor, cursor + chunk.length);
                  } else {
                    alert('Incorrect URL, your URL must start with "https://" or "http://"');
                  }
                }
              },
            }],
          },
          {
            name: 'groupCustomUtil',
            data: [{
              name: 'cmdCustomCheatsheet',
              title: 'Cheatsheet',
              hotkey: 'Ctrl+G',
              icon: {
                glyph: 'glyphicon glyphicon-info-sign',
                fa: 'fa fa-info-circle',
                'fa-3': 'icon-info-circle',
              },
              callback: function () {
                MarkdownCheatsheetModalView.showMe();
              },
            }],
          }],
        ],
        reorderButtonGroups: [
          'groupFont',
          'groupLink',
          'groupCustomLink',
          'groupMisc',
          'groupUtil',
          'groupCustomUtil',
        ],
      });
      var $mdEditor = $currentTextarea.parent();
      var $mdHeader = $mdEditor.children(':first');
      $mdHeader.addClass('hidden');
    }
  });

  $('.markdown-preview').each(function () {
    var $currentTextarea = $(this);
    if (!$currentTextarea.hasClass('md-input')) {
      $currentTextarea.markdown({
        autofocus: false,
        savable: false,
        hideable: false,
        width: 'markdown value no-dnd',
        resize: 'none',
        iconlibrary: 'fa',
        language: 'en',
        fullscreen: {
          enable: false,
          icons: {},
        },
        hiddenButtons: [
          'cmdBold',
          'cmdItalic',
          'cmdUrl',
          'cmdHeading',
          'cmdImage',
          'cmdList',
          'cmdListO',
          'cmdCode',
          'cmdQuote',
        ],
      });
    }
    var $mdEditor = $currentTextarea.parent();
    $mdEditor.removeClass('md-editor-disabled');
    var $mdPreviewButton = $mdEditor.children(':first').children(':last').children(':first');
    $mdPreviewButton.prop('disabled', false);
  });

  // Avoid exiting the CMS when cliking on links in preview
  $('.md-preview').on('click', 'a', (e) => {
    e.target.target = '_blank';
    e.target.rel = 'nofollow noopener noreferrer';
  });
}


function duplicatePage(nodeView) {
  var $add_page_button = $('#add-page-button');
  this.pageToCopy = nodeView.model;
  $add_page_button.trigger('click');
}

function duplicatePageByModel(model) {
  var $add_page_button = $('#add-page-button');
  this.pageToCopy = model;
  $add_page_button.trigger('click');
}

function expandRecursivly($element) {
  var $elementLiParent = $element.closest('.bt-item');
  var $associatedATags = $elementLiParent.find('a')
    .not('.json-edit-node')
    .not('.json-edit-node-tag')
    .not('.json-remove-node')
    .not('.json-disable-node')
    .not('.json-program-disabled');

  if ($elementLiParent && !$elementLiParent.hasClass('open')) {
    $elementLiParent.addClass('open');
    $associatedATags.first().trigger('click');
  } else {
    $elementLiParent.removeClass('open');
    $associatedATags.first().trigger('click');
  }

  var deep = 0;
  var $original_li = $($element.parents('li:eq(0)'));

  function open_if_father($item, currentDepth) {
    if ($item.hasClass('has-child')) {
      var i = $item
        .children('div.wrapper')
        .children('div.left-part')
        .children('a.toggle')
        .children('i.openable');

      i.attr('depth', currentDepth);
      i.addClass('open');
      i.trigger('click');
      return true;

    }
    return false;
  }

  function rec(li, currentDepth) {
    deep = Math.max(deep, currentDepth);
    var li_childrens = li.children('ul').children('li');
    li_childrens.get().forEach((li) => {
      var $li = $(li);
      var is_father = open_if_father($li, currentDepth);
      if (is_father) {
        rec($li, currentDepth + 1);
      }
    });
    return currentDepth;
  }


  if ($original_li.attr('deep')) {
    deep = $original_li.attr('deep');
    for (var i = deep; i > 0; i--) {
      var matching = $('[depth=' + (i) + ']').get();
      matching.forEach((item) => {
        $(item).removeClass('open');
        $(item).removeAttr('depth');
        $(item).trigger('click');
      });
    }

    $original_li.removeAttr('deep');
  } else {
    rec($original_li, 1);
    $original_li.attr('deep', deep);
  }
}

function onButtonClicked(event, nodeView, a) {

  event.preventDefault();
  var $element = $(event.target);
  if ($element.hasClass('json-add-node')) {
    this.addNode(nodeView);
  } else if ($element.hasClass('json-add-type') || $element.hasClass('json-add-oneof-type')) {
    this.addType(nodeView, $element.hasClass('json-add-oneof-type'));
  } else if ($element.hasClass('json-disable-node') && !$element.hasClass('locked')) {
    var enableDisabledText = ($element.hasClass('disabled')) ? 'enable' : 'disable';
    Confirm.showMe('You are about to ' + enableDisabledText + ' this element.', $.proxy(function () {
      this.toggleNode($element, nodeView, this);
    }, this), $.proxy(() => {
    }, this));
  } else if ($element.hasClass('json-remove-node')) {
    if (/^sharedComponents\.\d+$/.test(nodeView.model.get('ref'))) {
      Confirm.showMe('You are about to delete this element.', $.proxy(function () {
        this.model.removeNode(nodeView.model);
        this.updateAllReferenceNameDropdowns();
        this.updateAllCommercialReferenceNameDropdowns();
      }, this), $.proxy(() => {
      }, this), true, 'This will result in the deletion of all lines with this referenceName in your pages');
    } else {
      Confirm.showMe('You are about to delete this element.', $.proxy(function () {
        this.model.removeNode(nodeView.model);
        this.updateAllReferenceNameDropdowns();
        this.updateAllCommercialReferenceNameDropdowns();
      }, this), $.proxy(() => {
      }, this));
    }
  } else if ($element.hasClass('json-import-node')) {
    this._showImportNode(nodeView);
  } else if ($element.hasClass('json-edit-node-tag')) {
    this._showEditTag($element, nodeView);
  } else if ($element.hasClass('json-edit-node')) {
    this._showEditNode($element, nodeView);
  } else if ($element.hasClass('json-program-disabled')) {
    this._showProgramDisabledNode($element, nodeView);
  } else if ($element.hasClass('json-expandall-node')) {
    expandRecursivly($element);
  } else if ($element.hasClass('json-duplicatepage-node')) {
    this.duplicatePage(nodeView);
  }
}

function addType(nodeView, isOneOfType) {
  var guid = nodeView.model.get('guid');
  var $element = $('[data-guid="' + guid + '"]');
  var typeToAdd = (isOneOfType) ? $element.val() : $element.attr('data-type');
  this._addArrayLine(nodeView, typeToAdd, null, this.pageToCopy);
  this.pageToCopy = null;

}

function addNode(nodeView) {
  this._addArrayLine(nodeView);
}

function _showCurrentModale(contentId, localeId, currentJsonPath, arrayJsonPaths, callback) {
  if (arrayJsonPaths.length === 0) {
    callback(currentJsonPath);
    return;
  }
  SelectNodeModalView.showMe(contentId, localeId, currentJsonPath, true, $.proxy((listNodeToAdd) => {
    _showCurrentModale(contentId, localeId, `${listNodeToAdd[0]}.${arrayJsonPaths.shift()}`, arrayJsonPaths, callback);
  }, this));
}

function _showImportNode(nodeView) {
  LocalesView.showMe(this.content.locales, $.proxy(function (contentId, localeId) {
    let arrayJsonPaths = [''];
    let modaleCount = 0;
    TreeItemTools.getModelJsonPath(nodeView.model).split(Utils.JSON_PATH_SEPARATOR).forEach((node) => {
      if (Number.isInteger(Number.parseInt(node, 10))) {
        modaleCount++;
        arrayJsonPaths.push('');
      } else {
        arrayJsonPaths[modaleCount] = `${arrayJsonPaths[modaleCount] ? arrayJsonPaths[modaleCount] + '.' : ''}${node}`;
      }
    });

    let currentJsonPath = arrayJsonPaths.shift();
    _showCurrentModale(contentId, localeId, currentJsonPath, arrayJsonPaths,
      (newJsonPath) => SelectNodeModalView.showMe(contentId, localeId, newJsonPath, false, $.proxy(function (listNodeToAdd) {
        this._importNode(nodeView, listNodeToAdd);
      }, this)));

  }, this));
}

function updateReferenceNameDropdown(node) {
  const contentId = this.release?.get('content_id');
  const { key } = node.dataset;

  // Get the current value of the select dropdown
  const currentValue = node.value;

  const relevantReferenceNamesList = getRelevantSharedDataReferenceNames({ releaseValue: this.release?.get('value') ?? {}, contentId, key });
  const $select = $(node);

  $select.empty().append('<option value="">-- NO REFERENCE --</option>');
  relevantReferenceNamesList.forEach((referenceName) => {
    // Check if this was the previously selected option
    const isSelected = referenceName === currentValue;
    $select.append(`<option value="${referenceName}" ${isSelected ? 'selected' : ''}>${referenceName}</option>`);
  });

  $select.selectpicker('refresh');
  this.onBlurEditable({ target: node });
}

function updateCommercialReferenceNameDropdown(node) {
  const { key } = node.dataset;

  // Get the current value of the select dropdown
  const currentValue = node.value;

  const relevantReferenceNamesList = getReleventSharedCommercialComponentsReferenceNames({ releaseValue: this.release?.get('value') ?? {}, key });
  const $select = $(node);

  $select.empty().append('<option value="">-- NO REFERENCE --</option>');
  relevantReferenceNamesList.forEach((referenceName) => {
    // Check if this was the previously selected option
    const isSelected = referenceName === currentValue;
    $select.append(`<option value="${referenceName}" ${isSelected ? 'selected' : ''}>${referenceName}</option>`);
  });

  $select.selectpicker('refresh');
  this.onBlurEditable({ target: node });
}


function updateAllReferenceNameDropdowns() {
  Array.from($('.selectpicker.dcxSharedComponentsReferenceNamePicker')).forEach((node) => {
    this.updateReferenceNameDropdown(node);
  });
}

function updateAllCommercialReferenceNameDropdowns() {
  Array.from($('.selectpicker.dcxCommercialComponentsReferenceNamePicker')).forEach((node) => {
    this.updateCommercialReferenceNameDropdown(node);
  });
}

function _importNode(nodeView, listNodeToAdd) {
  const title = nodeView.model.get('title');
  const contentId = this?.release?.get('content_id') ?? '';
  const isImportOfPage = title === PAGES_STR;
  const isImportOfSharedData = title === getSharedDataProperty(contentId);
  for (var i = 0, l = listNodeToAdd.length; i < l; i++) {
    var nodeToAdd = listNodeToAdd[i];
    var typeToAdd = (nodeToAdd['@metadata'] && nodeToAdd['@metadata'].type) ? nodeToAdd['@metadata'].type : '';

    if (isImportOfPage) {
      delete nodeToAdd['_id'];
      delete nodeToAdd['b2c-pagesId'];
      delete nodeToAdd['old_position'];
    } else if (isImportOfSharedData) {
      delete nodeToAdd['_id'];
      delete nodeToAdd['b2c-pagesId'];
    }

    this._addArrayLine(nodeView, typeToAdd, listNodeToAdd[i]);
  }
}

function _showEditTag($element, nodeView) {
  var { title } = $element.data();
  const tagsMetadata = this.release.get('tagsMetadata')
  var nodeJsonPath = TreeItemTools.getModelJsonPath(nodeView.model);
  EditTagsModalView.showMe(this.release, nodeJsonPath, title, $.proxy(function (newTags) {
    this.release = TagsUtils.updateTagsList({
      release: this.release,
      newTags: _.clone(newTags),
      operation: 'add',
    }, tagsMetadata.prefix);

    var releaseValue = this.release.get('value');
    var element = _.get(releaseValue, nodeJsonPath);
    if (Utils.isNotEmptyObject(element)) {
      // Apply value modification
      var tagsJsonPath = TagsUtils.getTagsJsonPath(nodeJsonPath);
      this.release.setValue(tagsJsonPath, _.clone(newTags));

      if (this.releaseTags !== TagsUtils.ALL_TAGS_STRING && (this.releaseTags === TagsUtils.UNTAGGED_TAGS_STRING || newTags.indexOf(this.releaseTags) === -1)) {
        // Refresh treeView to remove tagged element
        this.model.refreshModel(nodeView.model, nodeJsonPath, null);
      }

      // Save new value
      this.releaseView.saveRelease();
    }
  }, this), tagsMetadata.prefix);
}

function _getReleaseDiff(newValue, nodeJsonPath) {
  const elementsToHide = [
    'b2c-pagesId',
    'old_position',
    '_id',
  ];

  if (this.releaseTags !== 'ALL') {
    const contentId = this.release.get('content_id');
    if (TagsUtils.jsonPathEqualsTaggedRootPath(contentId, nodeJsonPath)) {
      newValue = newValue.map((element) => {
        _.set(element, TagsUtils.TAGS_JSON_PATH, this.releaseTags);
        return element;
      });
    } else if (TagsUtils.jsonPathStartsWithTaggedRootPath(contentId, nodeJsonPath) && _.has(newValue, TagsUtils.TAGS_JSON_PATH)) {
      _.set(newValue, TagsUtils.TAGS_JSON_PATH, this.releaseTags);
    }
  }

  // get diff using jsonpatch and filter the elements to hide
  const diff =
    jsonpatch.compare(this.release.getValue(nodeJsonPath), newValue)
      .filter((diff) => !(elementsToHide.some((e) => diff.path.includes(e))));

  // return under the right format
  return diff.map((r) => ({
    operation: r.op,
    value: r.value,
    path: [nodeJsonPath, Utils.formatToJsonPath(r.path)].join('.'),
  }));
}

function _showEditNode($element, nodeView) {
  var { title } = $element.data();
  var nodeJsonPath = TreeItemTools.getModelJsonPath(nodeView.model);
  EditNodeModalView.showMe(this.release, nodeJsonPath, title, this.releaseTags, $.proxy(function (newValue) {
    if (Utils.isNotEmptyObject(newValue)) {

      this._getReleaseDiff(newValue, nodeJsonPath).forEach((diff) => {
        this.release.pushOperation(false, diff.operation, diff.path, diff.value);
      });

      this.release.setValue(nodeJsonPath, newValue);

      // Refresh treeView so that it displays the updated content
      this.model.refreshModel(nodeView.model, nodeJsonPath, newValue);

      // Save new value
      this.releaseView.saveRelease();
    }
  }, this));
}

function _showProgramDisabledNode($element, nodeView) {
  var registeredEnableDate = nodeView.model.get('enableStartDate') || '';
  var registeredDisableDate = nodeView.model.get('disableStartDate') || '';
  var nodeJsonPath = TreeItemTools.getModelJsonPath(nodeView.model);
  var metadataJsonPath = nodeJsonPath + '.@metadata';
  var enableStartDateJsonPath = metadataJsonPath + '.enableStartDate';
  var disableStartDateJsonPath = metadataJsonPath + '.disableStartDate';

  ProgramDisabledNodeModalView.showMe(registeredEnableDate, registeredDisableDate, $.proxy(function (enableStartDate, disableStartDate) {
    var updatedMetadata = _.cloneDeep(this.release.getValue(metadataJsonPath)) || {};
    updatedMetadata.enableStartDate = enableStartDate;
    nodeView.model.set('enableStartDate', enableStartDate);
    updatedMetadata.disableStartDate = disableStartDate;
    nodeView.model.set('disableStartDate', disableStartDate);
    var hasProgramDisabled = (enableStartDate || disableStartDate);
    nodeView.model.set('programDisabled', hasProgramDisabled);

    this.updateProgramDisabledCss($element, nodeView, enableStartDate, disableStartDate, this.release);

    var originalMetadata = this.release.getValue(metadataJsonPath);

    if (originalMetadata) {
      if (_.has(originalMetadata, 'enableStartDate')) {
        this.release.pushOperation(false, 'replace', enableStartDateJsonPath, enableStartDate);
      } else {
        this.release.pushOperation(false, 'add', enableStartDateJsonPath, enableStartDate);
      }
      if (_.has(originalMetadata, 'disableStartDate')) {
        this.release.pushOperation(false, 'replace', disableStartDateJsonPath, disableStartDate);
      } else {
        this.release.pushOperation(false, 'add', disableStartDateJsonPath, disableStartDate);
      }
    } else {
      this.release.pushOperation(false, 'add', metadataJsonPath, updatedMetadata);
    }

    // Save new value
    this.release.setValue(metadataJsonPath, updatedMetadata);
    this.releaseView.saveRelease();
  }, this));
}

function _addArrayLine(nodeView, typeToAdd, contentValue, pageToCopy) {


  var newChild = this.model.addArrayLine(nodeView, typeToAdd, contentValue, pageToCopy);


  this.putFocusOnFirstEditable(newChild.get ? newChild.get('guid') : newChild.guid);
  this.bindDOMEvents();
  this.initSpecialInputs();
}

function putFocusOnFirstEditable(guid) {
  $("[data-guid^='" + guid + "'].editable")
    .first()
    .focus();
}

function updateProgramDisabledCss($element, nodeView, enableStartDate, disableStartDate, release) {
  var hasProgramDisabledDates = (enableStartDate || disableStartDate);
  var nodeDisabled;

  if (hasProgramDisabledDates) {
    nodeDisabled = isProgramDisabled({
      enableStartDate: enableStartDate,
      disableStartDate: disableStartDate,
    });

  } else {
    var nodeJsonPath = TreeItemTools.getModelJsonPath(nodeView.model);
    var metadataJsonPath = nodeJsonPath + '.@metadata';
    var disabledJsonPath = metadataJsonPath + '.disabled';
    nodeDisabled = release.getValue(disabledJsonPath) || false;
  }

  // Update program disable CSS
  if (!hasProgramDisabledDates) {
    $element.removeClass('green');
    $element.removeClass('red');
  } else {
    if (nodeDisabled) {
      $element.removeClass('green');
      $element.addClass('red');
    } else {
      $element.removeClass('red');
      $element.addClass('green');
    }
  }

  // Update disable checkbox CSS
  var { guid } = $element.data();
  if (!guid) {
    guid = $element.attr('data-guid');
  }
  guid = guid.replace('.programDisable', '.disable');

  var $disable_i = $("[data-guid^='" + guid + "']").first();


  updateDisabledCss($disable_i, nodeView, hasProgramDisabledDates, nodeDisabled);
}


function updateDisabledCss($disable_i, nodeView, hasProgramDisabledDates, nodeDisabled) {
  var enabled = !nodeDisabled;
  var title = (enabled) ? 'Click to Disable' : 'Click to Disable';

  if (hasProgramDisabledDates) {
    title = 'Unavailable because there are programmed dates';
    $disable_i.removeClass('disabled fa-ban');
    $disable_i.removeClass('enabled fa-check');
    $disable_i.addClass('locked fa-lock');
    $disable_i.prop('title', title);
  } else {
    if (enabled) {
      $disable_i.removeClass('disabled fa-ban');
      $disable_i.removeClass('locked fa-lock');
      $disable_i.addClass('enabled fa-check');
      $disable_i.prop('title', title);

    } else {
      $disable_i.removeClass('enabled fa-check');
      $disable_i.removeClass('locked fa-lock');
      $disable_i.addClass('disabled fa-ban');
      $disable_i.prop('title', title);
    }


  }


  if (!enabled) {
    // TreeItemTools.closeNode($disable_i);
    TreeItemTools.toggleNodeInteractions({ $element: $disable_i, forceDisable: true });
  } else {
    TreeItemTools.toggleNodeInteractions({ $element: $disable_i, forceDisable: false });
    // TreeItemTools.openNode($disable_i);
  }
}

const valueTransformations = {
  '^external_services\\.\\d+\\.id$': (value) => value.trim(),
  // Add more transformations here
};

function applyValueTransformations(jsonPath, value) {
  for (const [pattern, transform] of Object.entries(valueTransformations)) {
    if (new RegExp(pattern).test(jsonPath)) {
      return transform(value);
    }
  }
  return value;
}

function onBlurEditable(event) {
  const $element = $(event.target);
  const guid = $element.data('guid') || $element.attr('data-guid');
  const treeNode = this.model.getNodeFromGuid(guid);
  if (!treeNode) {
    return;
  }
  const jsonPath = TreeItemTools.getModelJsonPath(treeNode);
  let newValue = $element.prop('tagName') === 'INPUT' ? $element.prop('checked') : $element.val();
  newValue = applyValueTransformations(jsonPath, newValue);
  $element.val(newValue);

  newValue = TreeItemTools.coerceType(this.release, jsonPath, newValue, treeNode);

  const oldValue = this.release.getValue(jsonPath);
  const contentValue = this.content.getValue(jsonPath);

  if (oldValue !== newValue) {
    this.release.setValue(jsonPath, newValue);
    $element.toggleClass('warning', contentValue === newValue);
    treeNode.set('value', newValue);
  }

  this.treeVisualiserView.trigger('blurField', newValue);
}

function bindDOMEvents() {
  this.bindEdit();
  this.bindDragAndDrop();
}

function bindEdit() {
  var $editable = $('.editable');
  $editable.off('focus');
  $editable.focus($.proxy(function (event) {
    var $element = $(event.target);
    var { guid } = $element.data();
    if (!guid) {
      guid = $element.attr('data-guid');
    }

    var node = this.model.getNodeFromGuid(guid);
    this.treeVisualiserView.trigger('focusField', $element, this.release, node);
  }, this));
  $editable.off('blur');
  $editable.blur($.proxy(this.onBlurEditable, this));

  var $select = $('select.editable');
  $select.change($.proxy(this.onBlurEditable, this));

  this.autoSizeEditField();

  var $textArea = $('textarea.editable');
  $textArea.off('change').off('keydown').off('keyup');
  $('textarea.editable')
    .change(this.autoSizeEditField)
    .keydown(this.autoSizeEditField)
    .keyup(this.autoSizeEditField)
    .focusout($.proxy(this.autoformat, this));

  var $markdown = $('.markdown');
  $markdown.off('focusin');
  $markdown.focusin($.proxy((event) => {
    var $mdInput = $(event.target);
    var $mdEditor = $mdInput.parent();
    var $mdHeader = $mdEditor.children(':first');
    $mdHeader.removeClass('hidden');
  }, this));
}

function bindDragAndDrop() {
  var $arrayElementRemoveButtons = $('.json-remove-node');
  $arrayElementRemoveButtons.each(function () {
    var $element = $(this);
    var $wrapper = $element.closest('.wrapper.clearfix');
    if ($wrapper && !$wrapper.is($element)) {
      // Reset no-dnd classes
      if (!$wrapper.hasClass('never-dnd')) {
        $wrapper.removeClass('no-dnd');
      }
      // Add no-dnd on opened objects
      var $parent = $wrapper.parent();
      if ($parent.hasClass('open')) {
        $wrapper.addClass('no-dnd');
      }
      // Only allow left click (== case 1) for drag and drop
      $wrapper.off('mousedown');
      $wrapper.mousedown((event) => {
        switch (event.which) {
          case 1:
            // when we do a drag&drop with a arrayline, the blur event is not trigered on "editable" field, and we loose the datas.
            // To resolve this problem, we triger manualy a "blur" event when we click on a arrayline
            $(':focus').blur();
            $wrapper.off('mousedown');
            $wrapper.on('mousedown', false);
            return true;
          case 2:
          case 3:
          default:
            event.stopImmediatePropagation();
            return false;
        }
      });
      $wrapper.off('mouseup');
      $wrapper.mouseup((event) => {
        switch (event.which) {
          case 1:
            return true;
          case 2:
          case 3:
          default:
            event.stopImmediatePropagation();
            return false;
        }
      });

      // Remove right click contextmenu display
      $wrapper.off('contextmenu');
      $wrapper.contextmenu((event) => {
        event.preventDefault();
        event.stopImmediatePropagation();
        return false;
      });
    }
  });
}


function openPath(path) {
  if (path) {
    TreeItemTools.traverseTreeNodes(this.model, (element) => {
      var currentRef = element.get ? element.get('ref') : element.ref;
      var open = TreeItemTools.isParent(path, currentRef);
      if (open) {
        element.set('open', true);
      }
    });
    $('.selectpicker').selectpicker();
  }
}

function scrollToPath(path) {
  this.openPath(path); // Open path first to generate html
  var self = this;
  var $element = $('[data-ref="' + path + '"]');
  $element.delay(200).queue(function () {
    $('html, body').animate({ scrollTop: $element.offset().top - 300 }, 1000, () => {
      self.bindDOMEvents();
      $element.focus().trigger('focus');
    });
    $(this).dequeue();
  });
}

function openCloseFiltered(event) {
  this.stopListening(this.model, 'change:open');
  var destRef = (event && event.id) ? event.id : '';
  TreeItemTools.traverseTreeNodes(this.tree.collection, (element) => {
    var currentRef = element.get ? element.get('ref') : element.ref;
    var disabled = (element && _.isBoolean(element.get('disabled'))) ? element.get('disabled') : false;
    var open = !event || !event.id || TreeItemTools.isParent(destRef, currentRef);
    if (!disabled && open) {
      // on laisse ouvert si on filtre sur rien, si c'est l'element sur lequel on filtre ou un de ses parents
      element.set('open', true);
    } else {
      element.set('open', false);
    }
  });
  this.initSpecialInputs();
}

function openErrors(event) {
  this.stopListening(this.model, 'change:open');
  var destRef = (event && event.id) ? event.id : '';
  TreeItemTools.traverseTreeNodes(this.model, (element) => {
    var currentRef = element.get ? element.get('ref') : element.ref;
    var open = event && event.id && TreeItemTools.isParent(destRef, currentRef);
    if (open) {
      element.set('open', true);
    }
  });
  $('.selectpicker').selectpicker();
  $('#tree-menu').find('[data-ref="' + destRef + '"]').addClass('cms-field--error');
  var $element = $('[data-ref="' + destRef + '"]');
  if ($element && !$element.children('.cms-label--error').length) {
    $element.before('<div class="cms-label--error">' + event.message + '</div>');
  }
}

function iniErrors() {
  $('.cms-label--error').remove();
  $('.cms-field--error').addClass('newValidate').removeClass('cms-field--error');
}

function listenEventsNodes() {
  this.bindDOMEvents();
  this.initSpecialInputs();
}

function autoSizeEditField(event) {
  var field;
  if (event) {
    field = event.target;
  } else {
    field = 'textarea.editable';
  }
  $(field).each(function () {
    var str = this.value;
    var { cols } = this;
    var linecount = 0;
    _.each(str.split('\n'), (line) => {
      linecount += Math.max(1, Math.ceil(line.length / cols));
    });
    this.rows = linecount;
  });
}


function autoformat(event) {
  var contentId = this.release.get('content_id');
  if (event.target) {
    $(event.target).each(function () {
      var { value } = this;
      var autoformatRule = '';
      if ($(this).hasClass('autoformat')) {
        autoformatRule = $(this).data('autoformatRule');
      }
      if ($(this).hasClass('markdown')) {
        var match = UrlUtils.MARKDOWN_URL_REGEX.exec(value);
        while (match != null) {
          var text = match[1];
          var spaces = match[2];
          var url = match[3];
          if (UrlUtils.isUrl(url)) {
            var originalMarkdownUrl = '[' + text + ']' + spaces + '(' + url + ')';

            var newUrl = UrlUtils.autoformatUrl(url);
            switch (autoformatRule) {
              case 'b2c-url':
              case 'dcx-url':
                newUrl = UrlUtils.autoformatB2cUrl(contentId, newUrl, true);
                newUrl = UrlUtils.autoformatB2cAnchor(contentId, newUrl, true);
                break;
              default:
                break;
            }

            var newMarkdownUrl = '[' + text + '](' + newUrl + ')';
            value = value.replace(originalMarkdownUrl, newMarkdownUrl);
          }
          // Find next markdown url
          match = UrlUtils.MARKDOWN_URL_REGEX.exec(value);
        }
      } else {
        if (UrlUtils.isUrl(value)) {
          value = UrlUtils.autoformatUrl(value);
          switch (autoformatRule) {
            case 'b2c-url':
            case 'dcx-url':
              value = UrlUtils.autoformatB2cUrl(contentId, value, true);
              value = UrlUtils.autoformatB2cAnchor(contentId, value, true);
              break;
            default:
              break;
          }
        } else {
          switch (autoformatRule) {
            case 'b2c-anchor':
            case 'dcx-anchor':
              value = UrlUtils.autoformatB2cAnchor(contentId, value, false);
              break;
            default:
              break;
          }
        }
      }
      this.value = value;
    });
  }
}

function isContentEditable() {
  if (this.release.get('status') === 'draft') {
    return account.isAllowed(this.release.get('content_id'), this.release.get('locale'), 'WRITE');
  }
  return false;

}

function isCurrentUserDev() {
  return account.isDev();
}

function getAllCheckedCheckbox() {
  return $('.checkbox-for-grouped-action:checked');
}

function editAllCallback(treeView) {
  var checkedCheckbox = getAllCheckedCheckbox();
  var indices = _.range(checkedCheckbox.length);
  var splittedTags = treeView.releaseTags.split(',');
  var defaultTag = (splittedTags.length >= 1) ? splittedTags[0] : '';
  var defaultSubTag = (splittedTags.length >= 2) ? splittedTags[1] : '';
  const tagsMetadata = treeView.release.get('tagsMetadata');

  EditMultipleTagsModalView.showMe(treeView.release, defaultTag, defaultSubTag, $.proxy((newTags) => {
    indices.forEach((it) => {
      var nodeView = {
        model: treeView.model.getNodeFromGuid(checkedCheckbox[it].attributes['data-guid'].value),
      };
      var nodeJsonPath = TreeItemTools.getModelJsonPath(nodeView.model);

      treeView.release = TagsUtils.updateTagsList({
        release: treeView.release,
        newTags: _.clone(newTags),
        operation: 'add',
      }, tagsMetadata.prefix);

      var releaseValue = treeView.release.get('value');
      var element = _.get(releaseValue, nodeJsonPath);

      if (Utils.isNotEmptyObject(element)) {
        // Apply value modification
        var tagsJsonPath = TagsUtils.getTagsJsonPath(nodeJsonPath);
        treeView.release.setValue(tagsJsonPath, _.clone(newTags));

        if (treeView.releaseTags !== TagsUtils.ALL_TAGS_STRING && (treeView.releaseTags === TagsUtils.UNTAGGED_TAGS_STRING || newTags.indexOf(treeView.releaseTags) <= 0)) {
          // Refresh treeView to remove tagged element
          treeView.model.refreshModel(nodeView.model, nodeJsonPath, null);
        }
        // Save new value
        treeView.releaseView.saveRelease();
      }
    });
  }, this), tagsMetadata.prefix);
}

function programAllCallback(treeView) {
  var checkedCheckbox = getAllCheckedCheckbox();
  var indices = _.range(checkedCheckbox.length);
  ProgramMultipleDisabledNodeModalView.showMe(null, null, $.proxy((enableStartDate, disableStartDate) => {
    indices.forEach((it) => {
      var nodeView = { model: treeView.model.getNodeFromGuid(checkedCheckbox[it].attributes['data-guid'].value) };
      var registeredEnableDate = nodeView.model.get('enableStartDate') || '';
      var registeredDisableDate = nodeView.model.get('disableStartDate') || '';
      var nodeJsonPath = TreeItemTools.getModelJsonPath(nodeView.model);
      var metadataJsonPath = nodeJsonPath + '.@metadata';
      var enableStartDateJsonPath = metadataJsonPath + '.enableStartDate';
      var disableStartDateJsonPath = metadataJsonPath + '.disableStartDate';
      var updatedMetadata = _.cloneDeep(treeView.release.getValue(metadataJsonPath)) || {};
      updatedMetadata.enableStartDate = enableStartDate;
      nodeView.model.set('enableStartDate', enableStartDate);
      updatedMetadata.disableStartDate = disableStartDate;
      nodeView.model.set('disableStartDate', disableStartDate);
      var hasProgramDisabled = (enableStartDate || disableStartDate);
      nodeView.model.set('programDisabled', hasProgramDisabled);

      updateProgramDisabledCss($('[data-ref="' + nodeJsonPath + '.programDisable"]'), nodeView, enableStartDate, disableStartDate, treeView.release);

      var originalMetadata = treeView.release.getValue(metadataJsonPath);
      if (originalMetadata) {
        if (_.has(originalMetadata, 'enableStartDate')) {
          treeView.release.pushOperation(false, 'replace', enableStartDateJsonPath, enableStartDate);
        } else {
          treeView.release.pushOperation(false, 'add', enableStartDateJsonPath, enableStartDate);
        }
        if (_.has(originalMetadata, 'disableStartDate')) {
          treeView.release.pushOperation(false, 'replace', disableStartDateJsonPath, disableStartDate);
        } else {
          treeView.release.pushOperation(false, 'add', disableStartDateJsonPath, disableStartDate);
        }
      } else {
        treeView.release.pushOperation(false, 'add', metadataJsonPath, updatedMetadata);
      }

      treeView.release.setValue(metadataJsonPath, updatedMetadata);
      treeView.releaseView.saveRelease();

    });

  }, this));
}

function toggleAllCallback(treeView) {

  var checkedCheckbox = getAllCheckedCheckbox();

  var indices = _.range(checkedCheckbox.length);
  Confirm.showMe('You are about to Enable/Disable multiple elements. ', $.proxy(() => {
    indices.forEach((it) => {
      var nodeView = { model: treeView.model.getNodeFromGuid(checkedCheckbox[it].attributes['data-guid'].value) };
      var nodeJsonPath = TreeItemTools.getModelJsonPath(nodeView.model);

      toggleNode($('[data-ref="' + nodeJsonPath + '.disable"]'), nodeView, treeView);
    });

  }, this), $.proxy(() => {
  }, this));

}

function deleteAllCallback(treeView) {
  var checkedCheckbox = getAllCheckedCheckbox();
  var indices = _.range(checkedCheckbox.length);

  Confirm.showMe('You are about to delete multiple elements.', $.proxy(() => {
    indices.forEach((it) => {
      treeView.model.removeNode(treeView.model.getNodeFromGuid(checkedCheckbox[it].attributes['data-guid'].value));
    });

  }, this), $.proxy(() => {
  }, this));
}

function duplicateAllCallback(treeView) {
  var checkedCheckbox = getAllCheckedCheckbox();
  var indices = _.range(checkedCheckbox.length);

  Confirm.showMe('You are about to copy multiple elements.', $.proxy(() => {
    indices.forEach((it) => {

      treeView.duplicatePageByModel(treeView.model.getNodeFromGuid(checkedCheckbox[it].attributes['data-guid'].value));
    });

  }, this), $.proxy(() => {
  }, this));
}


function bindGroupActionButtons(treeView) {

  $('#edit-all').off().click($.proxy(() => {
    editAllCallback(treeView);
  }, this));

  $('#program-all').off().click($.proxy(() => {
    programAllCallback(treeView);
  }, this));

  $('#toggle-all').off().click($.proxy(() => {
    toggleAllCallback(treeView);
  }, this));

  $('#delete-all').off().click($.proxy(() => {
    deleteAllCallback(treeView);
  }, this));

  $('#duplicate-all').off().click($.proxy(() => {
    duplicateAllCallback(treeView);
  }, this));
}


function render(selectedPath) {
  this.createModel();
  var contentEditable = this.isContentEditable();
  var isCurrentUserDev = this.isCurrentUserDev();
  var itemConstructor = new TreeItemFactory.TreeItemConstructor(contentEditable, isCurrentUserDev, this.release);
  this.tree = new BackTree.Tree({
    collection: this.model,
    settings: {
      animation: false,
      ItemConstructor: itemConstructor,
      plugins: {
        DnD: {
          changeParent: false,
        },
      },
    },
  });

  // to avoid having to bind the form fields each time a node is open
  // we hide the tree, initialize it fully opened (to force it to draw each leaf), bind the form fields, then close it and show it
  TreeItemTools.traverseTreeNodes(this.tree.collection, (element) => {
    element.set('open', false);
  });

  var treeRendered = this.tree.render();
  $('#tree-menu').append(treeRendered.$el);
  this.bindDOMEvents();
  this.tree.collection.on('dndStructureChanged', this.model.reorderReleaseNodes);
  $('#tree-menu').click($.proxy(this.listenEventsNodes, this));
  FilterOnEvent.on('filterOn', $.proxy(this.openCloseFiltered, this));
  FilterOnEvent.on('openErrors', $.proxy(this.openErrors, this));
  FilterOnEvent.on('iniErrors', $.proxy(this.iniErrors, this));
  this.treeVisualiserView = TreeVisualiserView.showMe();
  bindGroupActionButtons(this);
  this.delegateEvents();
  this.initSpecialInputs();
  if (selectedPath) {
    this.scrollToPath(selectedPath);
  }
  return this;
}

function showNew(releaseView, content, release, releaseTags, selectedPath, selectedCategory) {
  var treeView = new View();
  treeView.releaseView = releaseView;
  treeView.content = content;
  treeView.release = release;
  treeView.releaseTags = releaseTags || '';
  treeView.selectedCategory = selectedCategory;

  return treeView.render(selectedPath);
}

function toggleNode($element, nodeView, treeView, isMultipleAction) {


  if ($element.hasClass('disabled')) {
    $element.removeClass('disabled fa-ban ');
    $element.addClass('enabled fa-check');
  } else {
    $element.removeClass('enabled fa-check ');
    $element.addClass('disabled fa-ban ');
  }


  var disabled = $element.hasClass('disabled');
  var title = (disabled) ? 'Click to Enable' : 'Click to Disable';
  $element.prop('title', title);
  if (!disabled) {
    TreeItemTools.closeNode($element);
  }
  TreeItemTools.toggleNodeInteractions({ $element: $element });
  treeView.model.toggleNodeMetadata(nodeView.model, !disabled, $element);
  if (!disabled && !isMultipleAction) {
    TreeItemTools.openNode($element);
  }
}


View = Backbone.View.extend({
  close: close,
  createModel: createModel,
  initSpecialInputs: initSpecialInputs,
  updateReferenceNameDropdown: updateReferenceNameDropdown,
  updateCommercialReferenceNameDropdown: updateCommercialReferenceNameDropdown,
  updateAllReferenceNameDropdowns: updateAllReferenceNameDropdowns,
  updateAllCommercialReferenceNameDropdowns: updateAllCommercialReferenceNameDropdowns,
  onButtonClicked: onButtonClicked,
  addType: addType,
  addNode: addNode,
  _showImportNode: _showImportNode,
  _importNode: _importNode,
  _showEditTag: _showEditTag,
  _showEditNode: _showEditNode,
  _getReleaseDiff: _getReleaseDiff,
  duplicatePage: duplicatePage,
  duplicatePageByModel: duplicatePageByModel,
  _showProgramDisabledNode: _showProgramDisabledNode,
  _addArrayLine: _addArrayLine,
  putFocusOnFirstEditable: putFocusOnFirstEditable,
  updateProgramDisabledCss: updateProgramDisabledCss,
  toggleNode: toggleNode,
  updateDisabledCss: updateDisabledCss,
  onBlurEditable: onBlurEditable,
  bindDOMEvents: bindDOMEvents,
  bindEdit: bindEdit,
  bindDragAndDrop: bindDragAndDrop,
  openPath: openPath,
  scrollToPath: scrollToPath,
  openCloseFiltered: openCloseFiltered,
  openErrors: openErrors,
  iniErrors: iniErrors,
  listenEventsNodes: listenEventsNodes,
  autoSizeEditField: autoSizeEditField,
  autoformat: autoformat,
  isContentEditable: isContentEditable,
  isCurrentUserDev: isCurrentUserDev,
  render: render,
}, {
  showNew: showNew,
});

module.exports = View;
